import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/IT/layout'
import BannerLanding from '../../components/IT/BannerPartners'

import pic08 from '../../assets/images/pic08.jpg'
import pic09 from '../../assets/images/pic09.jpg'
import pic10 from '../../assets/images/pic10.jpg'
import ikg from '../../assets/imgs/ikg.png'
import csen from '../../assets/imgs/csen.png'
import alba from '../../assets/imgs/alba.png'
import act from '../../assets/imgs/act.png'
import asdb from '../../assets/imgs/ASDB.jpg'
import ce_a_butterfly from '../../assets/imgs/ceabutterfly.jpg'
import download from '../../assets/imgs/download.jpg'
import hoffnung from '../../assets/imgs/hoffnung.jpg'
import manadelucru from '../../assets/imgs/manadelucru.png'
import unefs from '../../assets/imgs/unefs.png'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        
                    </header>
                    <div style= {{width: "100%", textAlign:'center'}}>
                        <a style = {{ }} target = "_blank" href = "http://ikg.ro/despre-proiect/cine-suntem-noi/?lang=en"><img style = {{textDecoration: "none", height:'250px', width: 'auto', marginRight: '10px'}} src={ikg} /></a>
                        <a href = "http://www.csen.it/news/ultima-ora/581-progetto-europeo-unidans.html"><img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={csen} /></a>
                        <a href = "https://aalba.cat/ca/c/club-esportiu-alba-56"> <img style = {{height:'250px', width: 'auto', marginRight: '10px'}} src={alba} /></a>
                        
                        </div>
                </div>
            </section>
            {/*  <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Orci maecenas</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Rhoncus magna</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Sed nunc ligula</h3>
                            </header>
                            <p>Nullam et orci eu lorem consequat tincidunt vivamus et sagittis magna sed nunc rhoncus condimentum sem. In efficitur ligula tate urna. Maecenas massa sed magna lacinia magna pellentesque lorem ipsum dolor. Nullam et orci eu lorem consequat tincidunt. Vivamus et sagittis tempus.</p>
                            <ul className="actions">
                                <li><Link to="/generic" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div> 
                </section> 
            </section>*/}
        </div>

    </Layout>
)

export default Landing